export const ItaContent = {
  title: 'Nota Legale',
  content: [
    'Nome della ditta: iMile Italy S.r.l.',
    'Indirizzo Sede Legale: Via Giosuè Carducci, 15 20123 Milano',
    'Indirizzo PEC: imileitaly@registerpec.it',
    'Codice fiscale - Partita IVA e numero di iscrizione: 13027090961',
    'del Registro delle Imprese di MILANO MONZA BRIANZA LODI',
    'Numero REA: MI-2699003',
  ],
}

export const AusContent = {
  title: 'Legal Notice',
  content: [
    'Company Name: IMILE DELIVERY &LOGISTIC PTY LTD',
    'ACN: 664403785',
    'Email Address: customerservice-aus@imile.me',
    "Locality of Registered Office: WAREHOUSE 11B' OFFICE 11B 9-11 FERNDELL STREET SOUTH GRANVILLE NSW, SOUTH GRANVILLE, NSW 2142",
    '(Notice: We do not provide on-site customer service. Please make appointments if you have issues, as we do not accept unscheduled on-site visits.)',
  ],
}

export const enContent = {
  title: 'Legal Notice',
  content: [
    'Company Name: iMile Italy S.r.l.',
    'Registered office address: Via Giosuè Carducci, 15 20123 Milano',
    'PEC Address: imileitaly@registerpec.it',
    'Tax code - VAT number and registration number: 13027090961',
    'of the Register of Companies of MILAN MONZA BRIANZA LODI',
    'Number REA: MI-2699003',
  ],
}

export const plContent = {
  title: 'Informacja prawna',
  content: [
    'Nazwa: Imile logistics and transportation spółka z ograniczoną odpowiedzialnością',
    'Siedziba: Bielany Wrocławskie',
    'Adres: ul. Magazynowa 8, DC5, 55-040 Bielany Wrocławskie',
    'Wpisana do Rejestru Przedsiębiorców Krajowego Rejestru Sądowego przez Sąd Rejonowy ',
    'dla M.St. Warszawy w Warszawie, XIV Wydział Gospodarczy, pod nr KRS 0001008750 ',
    'NIP: 5252935916',
    'REGON:523946206',
    'Kapitał zakładowy w wysokości 5000 zł',
  ],
}

export const NZLcontent = {
  title: 'Legal Notice',
  content: [
    'Company Name: iMile Logistics Services Limited',
    'Company Number: 9210560',
    'NZBN: 9429052023507',
    'Registered Address: 33c Mccrystal Avenue, Eastern Beach Auckland 2012, New Zealand',
    'Contact Information: nz-office@imile.me',
  ],
}

export const EspContent = {
  title: 'Aviso Legal',
  content: [
    'Nombre de la empresa: iMile Logistics & Transportation, S.L.',
    'Dirección registrada: C. Cerrajeros, 16, 28830 San Fernando de Henares, Madrid',
    'Representante legal: Zongyang Zou',
    'Número de identificación fiscal: B55469589',
    'Información de contacto: esp[at] imile.me'
  ]
}

export const UAEContent = {
  title: 'إخطار قانوني',
  content: [
    'اسم الشركة: ايمايل للتوصيل ذ.م.م',
    'رقم الشركة: 793699',
    'العنوان المسجل: مستودع 01، قطعة رقم 93-0، مبنى يوروستار للاتصالات، مجمع دبي للاستثمار الأول ',
    'صندوق بريد 454158 ',
    'دبي ',
    'معلومات الاتصال: مسؤول حماية البيانات فييimile.me',
  ],
}

export const UAEenContent = {
  title: 'Legal Notice',
  content: [
    'Company Name: iMile Delivery Services L.L.C',
    'Company Number: 793699',
    'Registered Address: WH 01, PLOT NO 93-0',
    'EUROSTAR COMMUNICATIONS BUILDING,',
    'DUBAI INVESTMENT PARK FIRST',
    'PO BOX- 454158',
    'DUBAI, UNITED ARAB EMIRATES',
    'Contact Information: DPO [at] imile.me.',
  ],
}
